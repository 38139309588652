import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "objectFitPolyfill";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Footer from "../components/Footer";

import { fileSuffixFromWidth } from "../helpers";
import WindowWidthContext, { useWindowWidth } from "../context/WindowWidthContext";

import {
  StyledPageContainer,
  StyledBackgroundImage,
  StyledContentContainer,
  StyledTitle,
  StyledText,
  StyledButton,
} from "../pageStyles/404Styles";

export default () => {
  let redirectTimeout;

  function goToHomePage() {
    window.location.href = "/";
  }

  const data = useStaticQuery(graphql`
    query {
      _mobile_404: file(relativePath: { eq: "404/404_mobile.jpg" }) {
        childImageSharp {
          fixed(width: 320, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      _tablet_404: file(relativePath: { eq: "404/404_tablet.jpg" }) {
        childImageSharp {
          fixed(width: 768, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      _laptop_404: file(relativePath: { eq: "404/404_laptop.jpg" }) {
        childImageSharp {
          fixed(width: 1300, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      _desktop_404: file(relativePath: { eq: "404/404_desktop.jpg" }) {
        childImageSharp {
          fixed(width: 2550, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const {width} = useWindowWidth();

  return (
    <Layout>
      <SEO title="404: Not found" />

          <StyledPageContainer>
            <StyledBackgroundImage
              bgurl={data[`${fileSuffixFromWidth(width)}_404`].childImageSharp.fixed.src}
            >
              <StyledContentContainer>
                <StyledTitle>
                  Are you lost? <br />
                  error 404
                </StyledTitle>

                <StyledText>
                  Oops, something went wrong.
                  <br />
                  Please go to the main page by clicking the button below
                </StyledText>

                <StyledButton onClick={goToHomePage}>Cherry home</StyledButton>
              </StyledContentContainer>
            </StyledBackgroundImage>
            <Footer />
          </StyledPageContainer>
    </Layout>
  );
};
