import styled from 'styled-components';

export const StyledPageContainer = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    height: 73%;
  }
`;

export const StyledBackgroundImage = styled.div`
  width: 100%;
  height: 100vh;

  background: url(${props => props.bgurl});

  display: inline-block;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  } */

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    height: 73vh;
  }
`;

export const StyledContentContainer = styled.div`
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
  height: 100%;

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    justify-content: center;
  }
`;

export const StyledTitle = styled.h1`
  color: ${props => props.theme.primaryCherry};

  font-size: 32px;

  padding-left: 6%;

  width: 100%;

  margin-bottom: 1%;

  line-height: 1.5;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    font-size: 32px;

    padding-left: 15%;

    width: 42%;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    font-size: 48px;

    width: 36%;

    padding-left: 8%;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    /* font-size: 80px; */
    /* font-size: calc(100vw * 0.032); */
  }
`;

export const StyledText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;

  color: #424b5a;

  padding-left: 6%;

  width: 95%;

  height: 18%;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    width: 67%;
    height: 13%;

    padding-left: 15%;

    font-size: 16px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    width: 50%;

    padding-left: 8%;

    font-size: 16px;

    height: auto;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    font-size: 26px;

    width: 41%;
  }
`;

export const StyledButton = styled.button`
  margin-bottom: 6vh;
  width: 202px;
  height: 44px;

  background: ${props => props.theme.primaryCherry};
  color: white;

  border-radius: 22px;

  margin-left: 6%;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  min-height: 35px;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    margin-left: 15%;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    margin-top: 1vh;

    margin-left: 8%;
  }

  :active,
  :hover {
    background: #71003b;
  }
`;
